import {SubsystemCode} from '../types';

export const SUBSYSTEM_NAMES_MAP: {[subsystemCode in SubsystemCode]: string} = {
  Layout: 'КЗ',
  Structure: 'КР',
  Structure2: 'КР-2',
  HVAC: 'ОВиК',
  HVAC2: 'ОВиК-2',
  Plumbing: 'ВК',
  Plumbing2: 'ВК-2',
  Electrical: 'ЭОМ',
  Electrical2: 'ЭОМ-2',
  SpacePlanning: 'ОПР',
  OMAR: 'АР',
  OMAR2: 'АР-2',
  OMAR3: 'АР-3',
};
