import {
  BoundingBoxer,
  Clipper,
  Components,
  FragmentsManager,
  IfcLoader,
  IfcRelationsIndexer,
  SimpleCamera,
  SimpleGrid,
  SimpleScene,
  SimpleWorld,
  Worlds,
} from '@thatopen/components';
import {ClipEdges, EdgesPlane, Highlighter, Outliner, PostproductionRenderer} from '@thatopen/components-front';
import {FragmentsGroup} from '@thatopen/fragments';
import {
  Button,
  Component,
  html,
  Manager,
  Table,
  TableCellValue,
  TableRowData,
  TextInput,
  UpdateFunction,
} from '@thatopen/ui';
import {tables} from '@thatopen/ui-obc';
import {BehaviorSubject} from 'rxjs';
import * as THREE from 'three';
import {AmbientLight, Color, DirectionalLight, LineBasicMaterial, MeshBasicMaterial, Vector3} from 'three';
import {IFCSPACE} from 'web-ifc';

import {CustomClassifier, CustomExploder} from './custom-components';
import * as CustomUI from './ui';

customElements.define('ntc-ifc-toolbar', CustomUI.Toolbar);
customElements.define('ntc-ifc-button', CustomUI.Button);
customElements.define('ntc-ifc-toggle-button', CustomUI.ToggleButton);

export class IFCViewer {
  private readonly hostElement: HTMLElement | null = null;
  private readonly canvasElement: HTMLCanvasElement | null = null;

  private readonly components: Components = new Components();

  private world: SimpleWorld<SimpleScene, SimpleCamera, PostproductionRenderer> | null = null;
  private boundingBoxer: BoundingBoxer | null = null;
  private ifcLoader: IfcLoader | null = null;

  private readonly elementPropertiesTable: any;

  private readonly relationsTreeTable = tables.relationsTree({
    components: this.components,
    models: [],
  });

  private readonly relationsTree = this.relationsTreeTable[0];
  private readonly updateRelationsTree = this.relationsTreeTable[1];

  private propertiesTable: Table<TableRowData<Record<string, TableCellValue>>>;
  private updatePropertiesTable: UpdateFunction<any>;

  private readonly onViewerInit: () => void;

  private propertiesPanel: Component;
  private threePanel: Component;
  private initialSphere: THREE.Sphere;

  missingDataElement = Component.create(
    () => html`
      <div
        slot="missing-data"
        style="display: flex; flex-direction: column; align-items: center; width: 8rem; margin: auto;"
      >
        <bim-label>Нет данных</bim-label>
      </div>
    `,
  );

  closeIfc$ = new BehaviorSubject<boolean>(false);

  constructor(hostElement: HTMLElement, canvasElement: HTMLCanvasElement, initCallback?: () => void) {
    this.hostElement = hostElement;
    this.canvasElement = canvasElement;
    this.onViewerInit = initCallback ?? (() => null);
    this.components.init();

    this.initialize()
      .then(() => {
        this.onViewerInit();
      })
      .catch(error => {
        console.warn('Не удалось проинициализировать просмотр', error);
      });
  }

  async load(ifcFile: Blob): Promise<void> {
    try {
      this.showLoadingMessage();
      /** Выдергиваем из блоба буффер */
      const arrayBuffer = await ifcFile.arrayBuffer();
      /** Создаём целочисленный массив {@link Uint8Array} из {@link ArrayBuffer} */
      const unit8Array = new Uint8Array(arrayBuffer);
      /**
       * Прогоняем полученный массив через webIFC загрузчик и через библиотечную функцию
       * которая превращает IFC в набор Fragment-ов {@link FragmentsGroup}.
       *
       * Каждый фрагмент это обертка над стандартной группой объектов(мешей/фигур) из threejs
       */
      const fragments = await this.ifcLoader.load(unit8Array, true);

      /**
       * Добавляем полученные фрагменты на сцену
       */
      await this.addFragmentsGroupToScene(fragments);
      this.hideLoadingMessage();
    } catch (error: unknown) {
      console.error(error);
      this.hideLoadingMessage();
      this.showLoadingMessage(true);
    }
  }

  disposeAll(): void {
    this.components.dispose();
  }

  disposeModel(): void {
    // this.components.dispose();
  }

  private showLoadingMessage(error?: boolean): void {
    const loadingElement = document.createElement('div');

    loadingElement.id = 'loading-indicator';

    if (!error) {
      loadingElement.innerHTML = `<div class="info-block">
                    <div class="info-block__loader"></div>
                    <span class="info-block__text">Модель загружается, пожалуйста,</br> не закрывайте страницу</span>
                  </div>`;
    } else {
      loadingElement.innerHTML = `<div class="info-block">
                    <div class="info-block__icon">
                        <img src="./assets/icons/ifc-viewer/cancel.svg" alt="">
                    </div>
                    <span class="info-block__text">Невозможно загрузить модель</span>
                    <button class="info-block__close">Закрыть</button>
                  </div>`;
    }

    this.hostElement?.appendChild(loadingElement);
    const closeBtn = document.querySelector('.info-block__close');

    closeBtn?.addEventListener('click', () => {
      if (this.hostElement) {
        this.hostElement.style.pointerEvents = 'none';
      }

      closeBtn.setAttribute('disabled', '');

      this.closeIfc$.next(true);
    });
  }

  private hideLoadingMessage(): void {
    const loadingElement = this.hostElement?.querySelector('#loading-indicator');

    if (loadingElement) {
      loadingElement.remove();
    }
  }

  /** Инициализация всего компонента просмотрщика */
  private async initialize(): Promise<void> {
    this.ifcLoader = new IfcLoader(this.components);
    this.boundingBoxer = new BoundingBoxer(this.components);

    await this.initializeWorld();
    await this.initializeHightligher();
    await this.setupClipper();
    this.createGrid();
    Manager.init();

    this.relationsTree.preserveStructureOnFilter = true;

    await this.ifcLoader.setup({
      webIfc: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        CIRCLE_SEGMENTS: 100,
      },
      autoSetWasm: false,
    });

    /** Убираем из лоудера элементы не влияющие на отображение */
    const excludedCats: number[] = [IFCSPACE];

    for (const cat of excludedCats) {
      this.ifcLoader.settings.excludedCategories.add(cat);
    }

    this.initializeListeners();
    this.initializeToolbar();
  }

  private async initializeWorld(): Promise<void> {
    /** Создание отдельного «мира» для группировки элементов */
    this.world = this.components.get(Worlds).create();

    /** Создаём в новом «мире» сцену */
    this.world.scene = await this.createScene();

    /** Добавялем на сцену рендер-компонент */
    this.world.renderer = new PostproductionRenderer(
      this.components, // Указываем привязку к компонентам данного класса
      this.hostElement, // Элемент родительского контейнера
      {
        canvas: this.canvasElement, // Элемент канваса внутри родительского контейнера
        alpha: true, // прозрачность самого канваса
      },
    );

    /**
     * Добавляем в мир основную камеру.
     *
     * Требует для инициализации существующую Сцену и добавленный Renderer
     */
    this.world.camera = new SimpleCamera(this.components);

    /** Включаем для мира пост-продакшн для улучшения качества картинки */
    this.world.renderer.postproduction.enabled = true;
  }

  /** Запуск работы подсветки элементов и настройка внешнего вида свечения */
  private async initializeHightligher(): Promise<void> {
    const outliner = this.components.get(Outliner);
    const highlighter = this.components.get(Highlighter);

    highlighter.setup({world: this.world});

    outliner.world = this.world;
    outliner.enabled = true;
    outliner.create(
      'outliner',
      new MeshBasicMaterial({
        color: 0xbcf124,
        transparent: true,
        opacity: 0.5,
      }),
    );
  }

  private initializeListeners(): void {
    const outliner = this.components.get(Outliner);
    const highlighter = this.components.get(Highlighter);
    const clipper = this.components.get(Clipper);

    highlighter.events.select.onHighlight.add(fragmentIdMap => {
      outliner.clear('outliner');
      outliner.add('outliner', fragmentIdMap);

      if (this.propertiesPanel?.isConnected) {
        this.updatePropertiesTable({fragmentIdMap});
      }
    });

    highlighter.events.select.onClear.add(() => {
      outliner.clear('outliner');

      if (this.propertiesPanel?.isConnected) {
        this.updatePropertiesTable({fragmentIdMap: {}});
      }
    });

    this.hostElement.ondblclick = () => {
      if (clipper.enabled) {
        clipper.create(this.world);
      }
    };
  }

  // eslint-disable-next-line max-statements
  private async setupClipper(): Promise<void> {
    const edges = this.components.get(ClipEdges);
    const clipper = this.components.get(Clipper);

    clipper.setup();
    clipper.Type = EdgesPlane;

    const grayFill = new MeshBasicMaterial({color: 'gray', side: 2});
    const blackLine = new LineBasicMaterial({color: 'black'});
    const blackOutline = new MeshBasicMaterial({
      color: 'black',
      opacity: 0.5,
      side: 2,
      transparent: true,
    });

    edges.styles.create('thick', new Set(), this.world, blackLine, grayFill, blackOutline);
    edges.styles.create('thin', new Set(), this.world);
  }

  private async updateClipper(): Promise<void> {
    const classifier = this.components.get(CustomClassifier);
    const fragmentsManager = this.components.get(FragmentsManager);
    const edges = this.components.get(ClipEdges);

    const thickItems = classifier.find({
      entities: ['IFCWALLSTANDARDCASE', 'IFCWALL', 'IFCSLAB'],
    });

    const thinItems = classifier.find({
      entities: ['IFCDOOR', 'IFCWINDOW', 'IFCPLATE', 'IFCMEMBER'],
    });

    for (const fragID in thickItems) {
      const foundFrag = fragmentsManager.list.get(fragID);

      if (!foundFrag) {
        continue;
      }

      edges.styles.list.thick.fragments[fragID] = new Set(thickItems[fragID]);
      edges.styles.list.thick.meshes.add(foundFrag.mesh);
    }

    for (const fragID in thinItems) {
      const foundFrag = fragmentsManager.list.get(fragID);

      if (!foundFrag) {
        continue;
      }

      edges.styles.list.thin.fragments[fragID] = new Set(thinItems[fragID]);
      edges.styles.list.thin.meshes.add(foundFrag.mesh);
    }

    await edges.update(true);
  }

  private createGrid(): void {
    const grid = new SimpleGrid(this.components, this.world);

    grid.setup({color: new Color('#000')});
    this.world.renderer.postproduction.customEffects.excludedMeshes.push(grid.three);
  }

  private initializeToolbar(): void {
    const toolbar = Component.create<CustomUI.Toolbar>(() => {
      return html`
        <ntc-ifc-toolbar>
          <ntc-ifc-button
            icon="material-symbols:center-focus-weak-outline"
            tooltip-title="Отцентрировать"
            @click="${this.onZoomButtonClick.bind(this)}"
          ></ntc-ifc-button>
          <ntc-ifc-toggle-button
            icon="material-symbols:list-alt-outline"
            tooltip-title="Свойства"
            @togglechecked=${(ev: CustomUI.ToggleButtonCheckedEvent) => {
              this.onPropertiesButtonClick(ev.detail.value);
            }}
          ></ntc-ifc-toggle-button>
          <ntc-ifc-toggle-button
            icon="material-symbols:content-cut"
            tooltip-title="Создать сечение"
            @togglechecked=${(ev: CustomUI.ToggleButtonCheckedEvent) => {
              this.onClipperButtonClick(ev.detail.value);
            }}
          ></ntc-ifc-toggle-button>
          <ntc-ifc-toggle-button
            icon="material-symbols:view-day-outline"
            tooltip-title="Разбить на этажи"
            @togglechecked=${(ev: CustomUI.ToggleButtonCheckedEvent) => {
              this.onExplodeButtonClick(ev.detail.value);
            }}
          ></ntc-ifc-toggle-button>
          <ntc-ifc-toggle-button
            icon="material-symbols:account-tree-outline"
            tooltip-title="Дерево модели"
            @togglechecked=${(ev: CustomUI.ToggleButtonCheckedEvent) => {
              this.onThreeButtonClick(ev.detail.value);
            }}
          ></ntc-ifc-toggle-button>
        </ntc-ifc-toolbar>
      `;
    });

    this.hostElement.getElementsByClassName('toolbar')[0].appendChild(toolbar);
    this.hostElement.getElementsByClassName('toolbar')[0].setAttribute('style', 'bottom: 2rem; z-index: 2');

    [this.propertiesTable, this.updatePropertiesTable] = tables.elementProperties({
      components: this.components,
      fragmentIdMap: {},
    });
    this.propertiesTable.preserveStructureOnFilter = true;
    this.propertiesTable.indentationInText = false;

    this.propertiesPanel = Component.create(() => {
      const onTextInput = (e: Event) => {
        const input = e.target as TextInput;

        this.propertiesTable.queryString = input.value !== '' ? input.value : null;
      };

      const expandTable = (e: Event) => {
        const button = e.target as Button;

        this.propertiesTable.expanded = !this.propertiesTable.expanded;
        button.label = this.propertiesTable.expanded ? 'Свернуть' : 'Развернуть';
      };

      const copyAsTSV = async () => {
        await navigator.clipboard.writeText(this.propertiesTable.tsv);
      };

      return html`
        <bim-panel
          style="
          position: absolute;
          top: 1rem;
          left: 1rem;
          bottom: 1rem;
          width: 25vw;
          min-width: 20rem;
          max-width: 30rem;
          border: 1px solid #EDEDED;
        "
        >
          <div
            style="
            --bim-label--c: #191C30E5;
            --bim-label--fz: 20px;
            font-weight: 600;
            padding: 1.5rem;
            flex-shrink: 0;
            border: 1px solid #EDEDED;
            "
            class="section"
          >
            <bim-label>Свойства</bim-label>
          </div>
          <bim-panel-section>
            <div style="display: flex; gap: 0.5rem; margin-top: 1rem;">
              <bim-button
                label=${this.propertiesTable?.expanded ? 'Свернуть' : 'Развернуть'}
                @click=${expandTable}
              ></bim-button>
              <bim-button label="Скопировать в формате TSV" @click=${copyAsTSV}></bim-button>
            </div>
            <bim-text-input placeholder="Поиск..." debounce="250" @input=${onTextInput}></bim-text-input>
            ${this.propertiesTable}
          </bim-panel-section>
        </bim-panel>
      `;
    });

    this.threePanel = Component.create(() => {
      const onSearch = (e: Event) => {
        const input = e.target as TextInput;

        this.relationsTree.queryString = input.value;
      };

      return html`
        <bim-panel
          style="
          position: absolute;
          top: 1rem;
          right: 5rem;
          bottom: 1rem;
          width: 25vw;
          min-width: 20rem;
          max-width: 30rem;
          border: 1px solid #EDEDED;
          "
        >
          <div
            style="
            --bim-label--c: #191C30E5;
            --bim-label--fz: 20px;
            font-weight: 600;
            padding: 1.5rem;
            flex-shrink: 0;
            border: 1px solid #EDEDED;
            "
            class="section"
          >
            <bim-label>Дерево модели</bim-label>
          </div>
          <bim-panel-section style="margin-top: 1rem;">
            <bim-text-input placeholder="Поиск..." debounce="200" @input=${onSearch}></bim-text-input>
            ${this.relationsTree}
          </bim-panel-section>
        </bim-panel>
      `;
    });

    // Удаляем старую missing-data перед добавлением новой
    const existingMissingDataSlot = this.propertiesTable.querySelector('[slot="missing-data"]');

    if (existingMissingDataSlot) {
      existingMissingDataSlot.remove();
    }

    this.propertiesTable.append(this.missingDataElement);
  }

  private onClipperButtonClick(enable: boolean): void {
    const clipper = this.components.get(Clipper);

    clipper.enabled = enable;
    clipper.deleteAll();
  }

  private onPropertiesButtonClick(enable: boolean): void {
    if (enable) {
      this.hostElement.appendChild(this.propertiesPanel);
    } else {
      this.hostElement.removeChild(this.propertiesPanel);
    }
  }

  private onThreeButtonClick(enable: boolean): void {
    if (enable) {
      this.hostElement.appendChild(this.threePanel);
    } else {
      this.hostElement.removeChild(this.threePanel);
    }
  }

  private onExplodeButtonClick(enable: boolean): void {
    this.components.get(CustomExploder).set(enable);
    this.components.get(Highlighter).clear();
  }

  private onZoomButtonClick(): void {
    this.onCameraCenter();
  }

  /** Создание компонента простой сцены, библиотеки openbim, для работы со сценой threejs */
  private async createScene(): Promise<SimpleScene> {
    const scene = new SimpleScene(this.components);

    await scene.setup({
      backgroundColor: new Color('#fff'),
      ambientLight: new AmbientLight('white', 2),
      directionalLight: new DirectionalLight('white', 5),
    });

    return scene;
  }

  /**
   * Добавление группы фрагментов на сцену и обновление всех зависящих от них компонентов
   */
  private async addFragmentsGroupToScene(fragmentsGroup: FragmentsGroup): Promise<void> {
    /** Добавляем на сцену */
    this.world.scene.three.add(fragmentsGroup);
    /** Добавляем в BoundingBoxer, чтобы правильно расчитывался объем всех объектов на сцене */
    this.boundingBoxer.add(fragmentsGroup);

    /**
     * Каждый оригинальный мэш(фигура/3д-объект) добавляем в мир, чтобы с ними мог взаимодействовать рейкастер.
     *
     * Не будет мешей в мире – рейкастер будет стрелять сквозь фрагменты
     */
    for (const mesh of this.components.get(FragmentsManager).meshes) {
      this.world.meshes.add(mesh);
    }

    /** Запускаем процесс индексации всех дочерних фрагментов внутри группы */
    await this.components.get(IfcRelationsIndexer).process(fragmentsGroup);
    /** Разбиваем все фрагменты на группы для работы эксплоудера (для него объекты группируются поэтажно) */
    await this.components.get(CustomClassifier).bySpatialStructure(fragmentsGroup);
    await this.components.get(CustomClassifier).byEntity(fragmentsGroup);

    await this.updateClipper();

    this.initialSphere = this.boundingBoxer.getSphere();
    this.onCameraCenter();
  }

  private onCameraCenter() {
    /** На некоторых моделях радиус неправильно считается геометрия объекта,
     * из-за этого неправильно работает камера в THREE.js,
     * для подобных случаев, чтобы можно было посмотреть саму модель, добавлена дефолтная сфера.
     * Центровка камеры в этом случае работает неверно.
     * */

    const sphere = this.initialSphere.radius === Infinity ? new THREE.Sphere(new Vector3(), 35) : this.initialSphere;

    this.world.camera.controls.fitToSphere(sphere, true);
  }
}
